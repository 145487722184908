<template>
  <b-navbar toggleable="xs" class="row mx-0 sticky-top py-3" ref="navbar">
    <router-link to="/" draggable="false">
      <img
        :src="require('@/assets/europastry logo.svg')"
        class="logo"
        draggable="false"
      />
    </router-link>

    <!-- Right-side buttons -->
    <div class="row align-items-center mr-0">
      <!-- <LocaleChanger /> -->
      <!-- Edit Button -->
      <div class="navbar-margin">
        <img
          class="click-cursor navbar-button"
          @click="toggleEditMode"
          src="@/assets/icons/edit-button.svg"
          v-if="$route.path.includes('/product/') && canEdit"
          draggable="false"
        />
      </div>
      <!-- Profile Button -->
      <div class="navbar-margin">
        <b-dropdown
          size="md"
          variant="link"
          toggle-class="text-decoration-none p-0 navbar-fontsize"
          menu-class="dropdownSize"
          class="dropdownSize navbar-button"
          no-caret
        >
          <template v-slot:button-content>
            <img class="click-cursor" src="@/assets/icons/profile-button.svg" />
          </template>
          <b-dropdown-item class="navbarItem">Account</b-dropdown-item>
          <b-dropdown-item class="navbarItem" @click="signOutUser()">
            Sign Out
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- Upload Button -->
      <!-- <div class="navbar-margin" v-if="canEdit">
        <a @click.prevent="" v-b-modal.newproduct-modal>
          <img
            class="click-cursor navbar-button"
            src="@/assets/icons/upload-button.svg"
            draggable="false"
          />
        </a>
      </div> -->
      <!-- <CreateProductModal class="d-none" /> -->

      <!-- Hamburger Menu -->
      <div class="navbar-margin" style="margin-left: .9em!important">
        <img
          class="click-cursor navbar-button hamburger-menu-button"
          @click="$store.commit('toggleSidebar')"
          src="@/assets/icons/menu-button.svg"
          draggable="false"
        />
      </div>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
// import $ from "jquery";
// import LocaleChanger from "@/components/LocaleChanger.vue";

import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Navbar",
  components: {
    // CreateProductModal: () =>
    //   import("@/components/Product/CreateProductModal.vue")
  },
  data() {
    return {};
  },
  methods: {
    toggleEditMode: function() {
      this.$store.commit("toggleEditMode");
      let path = this.$route.fullPath;
      if (path.includes("/edit")) path = path.replace("/edit", "");
      else {
        path.endsWith("/") ? (path += "edit") : (path += "/edit");
      }
      this.$router.push(path);
      this.$store.commit("toggleSidebar", true);
    },
    signOutUser: function() {
      firebase.auth().signOut();
    }
  },
  computed: {
    // Link relevant vuex variables
    ...mapGetters(["editMode", "user"]),

    canEdit: function() {
      if (this.user) {
        if (this.user.admin) return true;
        if (this.user.roles === undefined) return false;
        return this.user.roles.length > 0;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.navbar {
  // height: 5em;
  background-color: white;
}

.no-border {
  outline: none !important;
  border: none !important;
}
.no-border:focus {
  border: none !important;
  outline: none !important;
}

.navbar-button {
  width: 2em;
  height: 2em !important;
}

.navbar-fontsize {
  font-size: 1rem !important;
}

.hamburger-menu-button {
  width: 2.9em !important;
}

.navbarItem {
  z-index: 1100;
  display: block;
}

.navbarIndex {
  z-index: 2000 !important;
}

.dropdownSize /deep/ ul {
  min-width: 7em !important;
}

.navbar-margin {
  margin-left: 0.7em !important;
}

.logo {
  width: 10em;
  color: #adadad;
}

/* .product-title {
  color: #d5d5d5;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  margin-left: -1rem;
} */
</style>
